<template>
  <div class="backend-modal">
    <!-- overlay -->
    <div id="overlay" class="overlay" @click="toggleSideBar('close')"></div>
    <!-- sidebar -->
    <div id="backend-sidebar" class="backend-sidebar">
      <div class="close-btn" @click="toggleSideBar('close')">X</div>
      <!-- logo -->
      <div class="logo">
        <img src="@/assets/website-use/logo_word.png" alt="" />
      </div>
      <hr />
      <div class="alert alert-light">
        <p class="tw-text-size20 text-center">{{ userInfo.name }}</p>
      </div>
      <ul class="sidebar-list">
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '訂閱列表' }"
          @click="go('訂閱列表')"
          v-if="viewPermissions.subscription"
        >
          <img src="@/assets/icon/comment-user.png" alt="" />訂閱列表
        </li>
        <!-- <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '註冊資訊' }"
          @click="go('註冊資訊')"
        >
          <img src="@/assets/icon/comment-user.png" alt="" />註冊資訊
        </li> -->
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '使用資訊' }"
          @click="go('使用資訊')"
          v-if="viewPermissions.usedInfo"
        >
          <img src="@/assets/icon/comment-user.png" alt="" />使用資訊
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '顧客帳號異動' }"
          @click="go('顧客帳號異動')"
          v-if="viewPermissions.accountsChange"
        >
          <img src="@/assets/icon/comment-user.png" alt="" />顧客帳號異動
        </li>
        <li class="mb-2 pointer">
          <a class="text-dark" @click="logout"
            ><i class="bi bi-house-fill me-2"></i>登出</a
          >
        </li>
      </ul>
    </div>
    <!-- sidebar toggle btn -->
    <div
      id="show-sidebar-btn"
      class="show-sidebar-btn hide-sidebar-btn d-flex align-items.center"
      @click="toggleSideBar('show')"
    >
      <span class="fw-bolder">{{ currentPage }}</span>
      <img src="@/assets/icon/angle-double-small-right.png" alt="" />
    </div>
    <!-- sidebar area -->
    <div id="sidebar-area" class="sidebar-area"></div>
    <!-- router view -->
    <div id="backend-content" class="backend-content">
      <div style="margin-top: 45px"></div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// api
import { parseToken, sortoutParseTokenResult } from '../../methods/API/parseToken'

export default {
  data() {
    return {
      b_serverToken: '',
      currentPage: '',
      userInfo: {},
      viewPermissions: {
        subscription: false,
        usedInfo: false,
        accountsChange: false,
      },
    }
  },
  created() {
    this.b_serverToken = this.$methods.getCookie('b_serverToken')
    this.checkCurrentPage()
    this.getUserInfo()
  },
  watch: {
    $route(to, from) {
      this.checkCurrentPage()
    },
  },
  methods: {
    // sidebar 切換
    toggleSideBar(status) {
      if (status === 'close') {
        $('#backend-sidebar').addClass('close-sidebar')
        $('#show-sidebar-btn').removeClass('hide-sidebar-btn')
        $('#sidebar-area').addClass('close-sidebar-area')
        $('#backend-content').addClass('content-width100')
        $('#overlay').removeClass('overlay')
      } else {
        $('#backend-sidebar').removeClass('close-sidebar')
        $('#show-sidebar-btn').addClass('hide-sidebar-btn')
        $('#sidebar-area').removeClass('close-sidebar-area')
        $('#backend-content').removeClass('content-width100')
        $('#overlay').addClass('overlay')
      }
    },
    // 前往
    go(where) {
      switch (where) {
        case "訂閱列表":
          this.$router.push({name: 'Subscription'})
          break;
        // case "註冊資訊":
        //   this.$router.push({name: 'RegisterInfo'})
        //   break;
        case "使用資訊":
          this.$router.push({name: 'UsedInfo'})
          break;
        case "顧客帳號異動":
          this.$router.push({name: 'AccountsChange'})
          break;
      }
      this.toggleSideBar('close')
    },
    // 檢查目前在哪一頁
    checkCurrentPage() {
      this.currentPage = ''
      switch (this.$route.name) {
        case "Subscription":
          this.currentPage = '訂閱列表'
          break;
        // case "RegisterInfo":
        //   this.currentPage = '註冊資訊'
        //   break;
        case "UsedInfo":
          this.currentPage = '使用資訊'
          break;
        case "AccountsChange":
          this.currentPage = '顧客帳號異動'
          break;
      }
    },
    // 取得使用者資訊
    getUserInfo() {
      this.$methods.switchLoading('show')
      this.userInfo = {}
      const result = parseToken(this.b_serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.userInfo = result
        this.checkCurrentUserViewPermissions(this.userInfo.ids[0])
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 檢查當前使用者查看頁面權限資格
    checkCurrentUserViewPermissions(id) {
      // 訂閱列表
      const subscription = ['2', '3', '5', '6']
      if(subscription.includes(id)) this.viewPermissions.subscription = true
      // 使用資訊
      const usedInfo = ['2', '6', '7']
      if(usedInfo.includes(id)) this.viewPermissions.usedInfo = true
      // 顧客帳號異動
      const accountsChange = ['2', '3', '5', '6']
      if(accountsChange.includes(id)) this.viewPermissions.accountsChange = true
    },
    // 登出
    logout() {
      this.$methods.delCookie('b_serverToken')
      this.$router.push('/backend/login')
    },
  }
}
</script>